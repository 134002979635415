import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { deviceMinW } from "../../styles/mediaQueries"
import VerticalSpacer from "./VerticalSpacer"
import logo from "../../images/descriptive-logo-purple.svg"
import { HeaderOne, TextButton } from "./Typography"
import RightContainer from "./RightContainer"
import { Controller, Scene } from "react-scrollmagic"
import { zIndex } from "../../styles/zIndex"

export const Hero = ({ heading = {}, isHome, ctaExists, isTan }) => {
    return (
        <>
            <HeroSection isHome={isHome}>
                {isHome && (
                    <>
                        <HeroLeftContainer>
                            <Controller>
                                <Scene triggerHook="onLeave" offset="-18" pin>
                                    <LogoLink to="/" id="home-logo">
                                        <LogoWrapper>
                                            <Logo
                                                src={logo}
                                                alt="Descriptive Logo"
                                                aria-label="Click here to go to the homepage."
                                            />
                                        </LogoWrapper>
                                    </LogoLink>
                                </Scene>
                            </Controller>
                        </HeroLeftContainer>
                        <VerticalSpacer size="m" />
                    </>
                )}
                <HeroRightContainer isHome={isHome}>
                    <HeaderOne isTan={isTan} copy={heading.header} />

                    {ctaExists && (
                        <>
                            <VerticalSpacer size="l" />
                            <TextButton
                                to={heading.slug}
                                copy={heading.cta}
                                hasLine
                                isTan={isTan}
                            />
                        </>
                    )}
                </HeroRightContainer>
            </HeroSection>
        </>
    )
}

export const HeroSection = styled.section`
    display: flex;
    justify-content: ${props => (props.isHome ? "space-between" : "flex-end")};
    flex-direction: ${props => (props.isHome ? "column" : "")};

    @media ${deviceMinW.mobileXL} {
        flex-direction: ${props => (props.isHome ? "row" : "")};
    }
`

export const HeroRightContainer = styled(RightContainer)`
    flex-direction: column;
`

const HeroLeftContainer = styled.div`

    @media ${deviceMinW.tablet} {
        justify-content: flex-start;
        flex-basis: width: calc( 100% * 1/6);
        max-width: calc( 100% * 1/6);
        width: calc( 100% * 1/6);
    }

`

const LogoWrapper = styled.div``
const Logo = styled.img`
    width: 10rem;

    @media ${deviceMinW.tablet} {
        width: 13rem;
    }
`

const LogoLink = styled(Link)`
    z-index: ${zIndex.desktopNav};
    display: ${({ menuOpen }) => (menuOpen ? "none" : "inline-block")};
`
